import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TPopularPromoResponse, TPromoResponse, TPromoXResponse} from 'modules/promo/models/PromoResponse';
import {selectPaginationType, selectPromoList} from 'modules/promo/selectors';
import {IPromoListParams} from 'modules/promo/services/PromoService';
import {actionSetPage, actionSetPageCount, actionSetPageSize} from 'modules/promo/slice';
import {actionSetPopularPromoList, actionSetPromoData, actionSetPromoList} from 'modules/promo/slice';
import {PAGINATION_TYPE} from 'plugins/modules/pagination/constants';

export const getPopularPromoList: TAppThunk<void> = async (dispatch, _, {promoService}) => {
    const data = await dispatch(
        requestAction<TPopularPromoResponse>({
            requestCb: () => promoService.getPopularPromoList(),
            type: actionSetPopularPromoList.type,
        })
    );

    if (data) {
        dispatch(actionSetPopularPromoList(data.actions));
    }
};

export const getPromoList =
    (
        searchQuery: URLSearchParams,
        params: IPromoListParams = {
            limit: 12,
        }
    ): TAppThunk<void> =>
    async (dispatch, getState, {promoService}) => {
        const data = await dispatch(
            requestAction<TPromoResponse>({
                requestCb: () => promoService.getPromoList(searchQuery, params),
                type: actionSetPromoList.type,
            })
        );

        if (!data?.actions) {
            return;
        }

        const {actions, page} = data;

        const paginationType = selectPaginationType(getState());

        const actionsList =
            paginationType === PAGINATION_TYPE.ACCUMULATIVE ? [...selectPromoList(getState()), ...actions] : actions;

        dispatch(actionSetPromoList(actionsList));

        if (page) {
            const {count, limit, page: pageNumber} = page;

            dispatch(actionSetPage(pageNumber));
            dispatch(actionSetPageSize(limit));
            dispatch(actionSetPageCount(count));
        }
    };

export const getPromo =
    (promoCode: string): TAppThunk<void> =>
    async (dispatch, _, {promoService}) => {
        try {
            const {action} = await dispatch(
                requestAction<TPromoXResponse>({
                    requestCb: () => promoService.getPromo(promoCode),
                    type: actionSetPromoData.type,
                })
            );

            if (!action) {
                return;
            }

            dispatch(actionSetPromoData(action));
        } catch (e) {
            console.error(e);
        }
    };
