import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {THomePageAdvicesResponse} from 'modules/home-page/models/HomePageAdvicesResponse';
import {THomePagePrivateLabelsResponse} from 'modules/home-page/models/HomePagePrivateLabelsResponse';
import {THomePageServicesResponse} from 'modules/home-page/models/HomePageServicesResponse';
import {
    actionSetHomeAdvicesList,
    actionSetHomePrivateLabelsList,
    actionSetHomeServiceList,
} from 'modules/home-page/slice';

export const getHomePrivateLabelsList: TAppThunk<void> = async (dispatch, _, {homePageService}) => {
    const data = await dispatch(
        requestAction<THomePagePrivateLabelsResponse>({
            requestCb: () => homePageService.getPrivateLabelsList(),
            type: actionSetHomePrivateLabelsList.type,
        })
    );

    dispatch(actionSetHomePrivateLabelsList(data.labels));
};

export const getHomeAdvicesList: TAppThunk<void> = async (dispatch, _, {homePageService}) => {
    const data = await dispatch(
        requestAction<THomePageAdvicesResponse>({
            requestCb: () => homePageService.getHomePageAdvicesList(),
            type: actionSetHomeAdvicesList.type,
        })
    );

    dispatch(actionSetHomeAdvicesList(data.advices));
};

export const getHomeServicesList: TAppThunk<void> = async (dispatch, _, {homePageService}) => {
    const data = await dispatch(
        requestAction<THomePageServicesResponse>({
            requestCb: () => homePageService.getHomePageServices(),
            type: actionSetHomeServiceList.type,
        })
    );

    dispatch(actionSetHomeServiceList(data.services));
};
