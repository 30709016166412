import {TAppRootState} from 'core/redux/types';
import {homePageModuleName} from 'modules/home-page/slice';
import {IHomePageState} from 'modules/home-page/types';

const select = (state: TAppRootState): IHomePageState => state[homePageModuleName];

export const selectPrivateLabelsList = (state: TAppRootState): IHomePageState['homePrivateLabelList'] =>
    select(state).homePrivateLabelList;

export const selectAdvicesList = (state: TAppRootState): IHomePageState['homeAdviceList'] =>
    select(state).homeAdviceList;

export const selectServicesList = (state: TAppRootState): IHomePageState['homeServiceList'] =>
    select(state).homeServiceList;
