import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TResponse} from 'core/types';
import {actionSetBreadCrumbsList} from 'modules/breadcrumbs/slice';
import {actionSetContentPage} from 'modules/content/slice';
import {ContentPage} from 'new-models';
import {PAGES} from 'routing/constants';

export const getContentPageByCode =
    (code: string | string[], options: {withBreadCrumbs?: boolean} = {withBreadCrumbs: false}): TAppThunk<void> =>
    async (dispatch, _, {contentService}) => {
        const {withBreadCrumbs} = options;
        const data = await dispatch(
            requestAction<TResponse<ContentPage>>({
                requestCb: () => contentService.getContentPageByCode(code),
                type: actionSetContentPage.type,
            })
        );

        const page = {
            path: '#',
            title: data?.title,
        };

        if (withBreadCrumbs && !data?.image && data?.title) {
            dispatch(actionSetBreadCrumbsList([PAGES.HOME_PAGE, page]));
        }

        dispatch(actionSetContentPage(data));
    };
