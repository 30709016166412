import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {CollectionDetail, CollectionShort} from 'models';
import {
    TCollectionsListResponse,
    TPopularCollectionsListResponse,
    TRelatedCollectionsListResponse,
} from 'modules/collections/models';
import {selectList, selectPaginationType} from 'modules/collections/selectors';
import {
    actionSetFilterList,
    actionSetList,
    actionSetPage,
    actionSetPageCount,
    actionSetPageSize,
    actionSetPopularCollectionList,
    actionSetRelatedCollectionList,
} from 'modules/collections/slice';
import {Category} from 'new-models';
import {makeListingLoadingId} from 'plugins/modules/listing/helpers';
import {PAGE_SIZE, PAGINATION_TYPE} from 'plugins/modules/pagination/constants';

export const getRelatedCollectionsList =
    (query: URLSearchParams, collectionRefId: CollectionDetail['id'], categoryId: Category['id']): TAppThunk<void> =>
    async (dispatch, _, {collectionsService}) => {
        const data = await dispatch(
            requestAction<TRelatedCollectionsListResponse>({
                requestCb: () =>
                    collectionsService.getRelatedCollections(query, collectionRefId, {
                        categoryId,
                    }),
                type: actionSetRelatedCollectionList.type,
            })
        );

        dispatch(actionSetRelatedCollectionList(data.collections));
    };

export const getPopularCollectionList: TAppThunk<void> = async (dispatch, _, {collectionsService}) => {
    const data = await dispatch(
        requestAction<TPopularCollectionsListResponse>({
            requestCb: () => collectionsService.getPopularCollections(),
            type: actionSetPopularCollectionList.type,
        })
    );

    if (data) {
        dispatch(actionSetPopularCollectionList(data.collections));
    }
};

export const getCollections =
    (query: URLSearchParams): TAppThunk<void> =>
    async (dispatch, getState, {collectionsService}) => {
        const paginationType = selectPaginationType(getState());

        const data = await dispatch(
            requestAction<TCollectionsListResponse>({
                requestCb: () =>
                    collectionsService.getCollections(query, {
                        limit: PAGE_SIZE,
                    }),
                type: makeListingLoadingId(actionSetList.type, paginationType),
            })
        );

        dispatch(actionSetPage(data.page.page));
        dispatch(actionSetPageCount(data.page.count));
        dispatch(actionSetPageSize(data.page.limit));

        dispatch(actionSetFilterList(data.filters));

        const collectionsList =
            paginationType === PAGINATION_TYPE.ACCUMULATIVE
                ? [...selectList<CollectionShort>(getState()), ...data.collections]
                : data.collections;

        dispatch(actionSetList(collectionsList));
    };
