import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TShopListResponse} from 'modules/shops/models/ShopListResponse';
import {actionSetShopList} from 'modules/shops/slice';

export const getShopList: TAppThunk<void> = async (dispatch, _, {shopService}) => {
    const data = await dispatch(
        requestAction<TShopListResponse>({
            requestCb: () => shopService.getStores(),
            type: actionSetShopList.type,
        })
    );

    dispatch(actionSetShopList(data || {}));
};
